import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/seo";

import { Link } from "../components/typography/Link";

const IndexPage: React.FC = () => {
  return (
    <Layout header={false}>
      <SEO title="Home" />
      <main itemScope itemType="https://schema.org/LocalBusiness">
        <meta itemProp="name" content="Panic Bear Climbing" />

        <div className="flex items-center justify-center mt-24">
          <div className="text-left mx-6">
            <div className="font-bold text-xl">Panic Bear</div>
            <div className="text-lg pl-6">
              <span className="italic">n.</span> A panicking novice climber
              clinging to handholds while searching desperately for a foothold.
              <sup>
                <Link href="https://en.wikipedia.org/wiki/Glossary_of_climbing_terms#panic_bear">
                  1
                </Link>
              </sup>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center mt-8 text-xl font-bold">
          <div>Guiding</div>
          <div className="mx-8 font-light">|</div>
          <div>Training</div>
          <div className="mx-8 font-light">|</div>
          <div>Gear</div>
        </div>

        <div className="flex items-center justify-center mt-8">
          <h6 className="text-2xl text-center">
            <span className="contact__text">Contact Us: </span>
            <Link href="mailto:info@panicbearclimbing.com">
              <span itemProp="email">info@panicbearclimbing.com</span>
            </Link>
          </h6>
        </div>
      </main>

      <div className="mb-8"></div>
    </Layout>
  );
};

export default IndexPage;
